import { M140ProductListModule } from '~/lib/data-contract';

export const getActiveM140Categories = (
    productCategoryIds: M140ProductListModule['productCategoryIds'],
) => {
    if (productCategoryIds?.length) {
        return productCategoryIds;
    }
    return [];
};
